// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header__container{position:fixed;top:0;z-index:9;width:100%}.Header__toolbar{display:flex;justify-content:space-between;padding:0 24px;height:56px;font-weight:200;color:#fff;background-color:#1570ef}.Header__toolbar.Header__prod{background-color:#ff9800}.Header__toolbar.Header__onboarding{justify-content:center}.Header__toolbar-section{position:relative;display:flex;justify-content:space-between;align-items:center}.Header__toolbar-title{line-height:48px;font-size:20px;position:relative;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;color:#fff}.Header__user-menu{display:flex;align-items:center;margin:1px 0 -1px}.Header__user{margin:0;font-size:14px;cursor:pointer}.Header__link{text-decoration:none}.Header__badge{padding:0px 3px;min-width:10px;height:16px;border-radius:8px;background-color:#ff9800;color:#fff;position:absolute;top:6px;right:4px;font-size:12px;font-weight:600;display:none;align-items:center;justify-content:center}.Header__badge.Header__show{display:flex}\n", ""]);
// Exports
exports.locals = {
	"container": "Header__container",
	"toolbar": "Header__toolbar",
	"prod": "Header__prod",
	"onboarding": "Header__onboarding",
	"toolbar-section": "Header__toolbar-section",
	"toolbar-title": "Header__toolbar-title",
	"user-menu": "Header__user-menu",
	"user": "Header__user",
	"link": "Header__link",
	"badge": "Header__badge",
	"show": "Header__show"
};
module.exports = exports;
