import { cyan, red } from "@material-ui/core/colors";
import FocalPointsLogo from "../img/focalpointslogo.svg";
import FocalPointsFav from "../img/fp-fav.svg";

const IS_PRODUCTION = process.env.NODE_ENV === "production";

const OPTIONS = {
  localhost: {
    // Allows "route" creation
    CreateAccount: {
      route: false
    },
    ResetPassword: {
      route: true
    },
    Signup: {
      route: true
    },
    Integrations: {
      route: true
    },
    Inbox: {
      route: true,
      show: true
    },
    Dashboard: {
      route: true,
      show: true
    },
    Discover: {
      route: true,
      show: true
    },
    Scoring: {
      show: true,
      AgentTable: {
        route: true
      },
      Randomizer: {
        route: true
      }
    },
    Library: {
      route: true
    },
    Settings: {
      route: true,
      Personal: {
        route: true
      },
      Users: {
        route: true,
        allowUserManagement: true,
        allowManagerManagement: true
      },
      Teams: {
        route: true
      },
      Scorecards: {
        route: true
      },
      Metadata: {
        route: true
      },
      Integrations: {
        route: true
      },
      Developers: {
        route: true
      }
    },
    Onboarding: {
      route: true
    },
    Documentation: {
      main: "https://voxjar.com/resources/docs/overview/",
      five9: "https://voxjar.com/resources/docs/integrations/five9/",
      fuze: "https://voxjar.com/resources/docs/integrations/fuze/",
      incontact: "https://voxjar.com/resources/docs/integrations/incontact/",
      jive: "https://voxjar.com/resources/docs/integrations/jive/",
      orecx: "https://voxjar.com/resources/docs/integrations/orecx/",
      tcn: "https://voxjar.com/resources/docs/integrations/tcn/",
      ringcentral: "https://voxjar.com/resources/docs/integrations/ringcentral/"
    },
    // login:{oauth:{OIDC config "settings"}},
    login: { voxjar: true },
    navName: "Interaction Analytics",
    contentName: "Interaction Analytics",
    logo: FocalPointsLogo,
    favicon: FocalPointsFav,
    // TODO: somehow import these colors into theme.scss
    primaryColor: "#1570EF",
    secondaryColor: "#A1C6F9",
    accentColor: cyan[400],
    errorColor: red[500],
    notificationEndpoint: "test.voxjar.com",
    supportEmail: "support@voxjar.com",
    calendly: "https://calendly.com/lori-brown-vo0/voxjar/invitees",
    origin: IS_PRODUCTION ? "https://app.voxjar.com" : "http://localhost:9002",
    language: null,
    redirectBaseUrl: IS_PRODUCTION
      ? "https://app.voxjar.com/oauth"
      : " http://localhost:9002/oauth"
  }
};

export { OPTIONS };
