import { OPTIONS } from "./configOptions.js";
// import { FirebaseGetConfig } from "./utils/firebase.jsx";
import _ from "lodash";

const IS_PRODUCTION = process.env.NODE_ENV === "production";
// const IS_PRODUCTION = true;
// console.log("production environment:", IS_PRODUCTION);

// const projectId = IS_PRODUCTION ? "platoai-prod" : "platoai-dev";
// const apiKey = IS_PRODUCTION
//   ? "AIzaSyDByJeTza492DawWctoPtraxjTVbCBzNRI"
//   : "AIzaSyDbjASxiFnADr4w9r1bBpMDjHXtFAnLJgc";

// const FIREBASE_CONFIG = {
//   apiKey: apiKey,
//   authDomain: `${projectId}.firebaseapp.com`,
//   databaseURL: `https://${projectId}.firebaseio.com`,
//   storageBucket: `${projectId}.appspot.com`
// };

const SITE = window.location.hostname;
// const SITE_KEY =
//   SITE == "localhost"
//     ? "localhost"
//     : /:\/\/([^\/]+)/.exec(window.location.href)[1];
// console.log(SITE_KEY)

const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAHzyhAEOTxer1Z-L9zHOswJAH7C6tmTlw",
  // authDomain: "platoaiinc.firebaseapp.com", //check to see if this changes the email domains and use SITE if it does
  // authDomain: 'http://localhost:9002',
  // authDomain: "voxjar-tmp.web.app",
  projectId: "ccaas-untangl"
};
const VOXJAR_API_SERVER_URI =
  process.env.VOXJAR_API_SERVER_URI ||
  (IS_PRODUCTION
    ? "https://api.focalpoints.ai/graphql"
    : "https://api.focalpoints.ai/graphql");
// : "https://api-dev.voxjar.com/graphql");
// `https://api-prod-keumr7bnra-uc.a.run.app`;
// `https://api.voxjar.com:${IS_PRODUCTION ? 9000 : 9001}/graphql`;
// `http://williams-mbp.home:${IS_PRODUCTION ? 9000 : 9001}/graphql`;
// `http://coruscant:${IS_PRODUCTION ? 9000 : 9001}/graphql`;
// `https://api-dev.voxjar.com/graphql`;

// Whitelable site settings
// TODO: maybe extend this for all accounts - charge more/less for certain features

// console.log(SITE)
// const sample_config =async ()=> {await FirebaseGetConfig(SITE_KEY)
// console.log(sample_config())

const SITE_CONFIG = _.get(OPTIONS, SITE, OPTIONS["localhost"]);

export { FIREBASE_CONFIG, VOXJAR_API_SERVER_URI, IS_PRODUCTION, SITE_CONFIG };
