// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ResetRequest__alert{position:fixed;top:10%;left:0;right:0;width:500px;margin:0 auto;text-align:center;background-color:#272727;color:#fff;padding:16px;box-shadow:0px 4px 6px 0px rgba(0,0,0,0.4)}.ResetRequest__logo{margin:16px auto;width:200px;display:block}.ResetRequest__title{font-weight:600;text-align:center;line-height:normal}.ResetRequest__info{margin-top:12px;font-size:12px;font-weight:600;text-align:center}.ResetRequest__error{margin-top:24px;padding:16px;width:100%;color:red;background-color:rgba(255,0,0,0.08);font-size:12px;line-height:normal;box-sizing:border-box}.ResetRequest__forgot{margin:8px 0 24px;font-size:14px}.ResetRequest__post-info{margin-top:16px;text-align:center}.ResetRequest__reset-link{color:#1570ef;text-decoration:underline;cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"alert": "ResetRequest__alert",
	"logo": "ResetRequest__logo",
	"title": "ResetRequest__title",
	"info": "ResetRequest__info",
	"error": "ResetRequest__error",
	"forgot": "ResetRequest__forgot",
	"post-info": "ResetRequest__post-info",
	"reset-link": "ResetRequest__reset-link"
};
module.exports = exports;
