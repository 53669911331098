// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Loading__container{display:flex;justify-content:center}.Loading__container.Loading__overlay{position:absolute;top:0;left:0;right:0;bottom:0;align-items:center}.Loading__container.Loading__greyoverlay{position:absolute;top:0;left:0;right:0;bottom:0;background:#80808040;align-items:center;z-index:9999999}.Loading__inner-container{display:flex;flex-direction:column;align-items:center}.Loading__message{position:absolute;margin-top:8px;color:#1570ef}\n", ""]);
// Exports
exports.locals = {
	"container": "Loading__container",
	"overlay": "Loading__overlay",
	"greyoverlay": "Loading__greyoverlay",
	"inner-container": "Loading__inner-container",
	"message": "Loading__message"
};
module.exports = exports;
