// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CreateAccount__logo{margin:16px auto;width:200px;display:block}.CreateAccount__title{font-weight:600;text-align:center;line-height:normal}.CreateAccount__info{margin-top:12px;font-size:12px;font-weight:600;text-align:center}.CreateAccount__error{margin-top:24px;padding:16px;width:100%;color:red;background-color:rgba(255,0,0,0.08);font-size:12px;line-height:normal;box-sizing:border-box}.CreateAccount__post-info{margin-top:16px;text-align:center}.CreateAccount__reset-link{color:#1570ef;text-decoration:underline;cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"logo": "CreateAccount__logo",
	"title": "CreateAccount__title",
	"info": "CreateAccount__info",
	"error": "CreateAccount__error",
	"post-info": "CreateAccount__post-info",
	"reset-link": "CreateAccount__reset-link"
};
module.exports = exports;
