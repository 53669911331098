// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignInAsUserDialog__row{position:relative;display:flex}.SignInAsUserDialog__helper-text{display:flex;align-items:center;white-space:nowrap}.SignInAsUserDialog__autocomplete{margin-right:16px}.SignInAsUserDialog__popper{top:64px;width:100% !important}.SignInAsUserDialog__paper{height:calc(400px - 16px - 64px);overflow:auto}.SignInAsUserDialog__icon-button{position:absolute;top:8px;right:8px;color:inherit}.SignInAsUserDialog__option{border-bottom:1px solid #cfd8dc}.SignInAsUserDialog__option:first-child{border-top:1px solid #cfd8dc}.SignInAsUserDialog__search-text{color:#1570ef;font-weight:600}\n", ""]);
// Exports
exports.locals = {
	"row": "SignInAsUserDialog__row",
	"helper-text": "SignInAsUserDialog__helper-text",
	"autocomplete": "SignInAsUserDialog__autocomplete",
	"popper": "SignInAsUserDialog__popper",
	"paper": "SignInAsUserDialog__paper",
	"icon-button": "SignInAsUserDialog__icon-button",
	"option": "SignInAsUserDialog__option",
	"search-text": "SignInAsUserDialog__search-text"
};
module.exports = exports;
